import { SafetyCodeContext } from '@canalplus/types-acm';
import type { OneDiscoveryTranslations } from './types';

const LOCALE_FR_FR: OneDiscoveryTranslations = {
  A11y: {
    disableAnimationsAutoplay: 'Désactiver les animations',
    enableAnimationsAutoplay: 'Activer les animations',
    lastCategory:
      'Continuer la navigation pour parcourir la dernière catégorie',
    nextCategory: 'Catégorie suivante',
    nextCategoryAriaLabel:
      'Vous êtes sur la catégorie "%{category}". Pour passer à la catégorie suivante, cliquer sur Entrée',
    skipToMainContent: 'Aller au contenu principal',
    skipToMenu: 'Aller au menu',
    skipToSearch: 'Aller à la recherche',
    headerLogo: '%{appName} - accueil',
    navigationMenu: 'Menu de navigation principal',
  },
  ActionBar: {
    twitterShare: 'Regardez %{movie} sur #CANAL',
    twitterShareSite: 'myCANAL',
  },
  Alert: {
    attention: 'Attention',
    error: 'Erreur',
    information: 'Information',
    success: 'Succès',
    ignore: 'Ignorer',
  },
  AudioLanguage: {
    Icon: {
      vf: {
        title: 'VF',
        description: 'Version française',
      },
      vost_vf: {
        title: 'VOST/VF',
        description: 'Version originale sous-titré français',
      },
      vost: {
        title: 'VOST',
        description: 'Version originale sous-titré',
      },
    },
  },
  Authentication: {
    login: 'Se connecter',
    logout: 'Se déconnecter',
  },
  AvailabilityLabel: {
    until: "Jusqu'au",
    promotion: 'Promo',
    minimumPrice: 'Dès',
    free: 'Offert',
  },
  Card: {
    withSeason: 'Avec la saison',
    notInterested: 'Pas interessé',
    removeFromList: 'Retirer de la liste',
    season: 'Saison',
    subscribeLabel: "S'abonner",
  },
  Common: {
    validate: 'Valider',
    modify: 'Modifier',
    delete: 'Supprimer',
    yes: 'Oui',
    no: 'Non',
  },
  Contact: {
    title: 'Vérification',
    subtitle: 'Choisissez le moyen de réception du code de vérification',
    type: {
      EMAIL: 'Email',
      PHONE: 'SMS',
    },
  },
  CreativeMedia: {
    ActionBar: {
      twitterShare: 'Regardez %{movie} sur #CANAL+',
    },
    MediaAuthor: {
      published: 'Posté ',
      author: 'par %{author} ',
      date: 'le %{date}',
    },
    Sharing: {
      title: 'Partager sur',
    },
    Card: {
      article: 'Article',
    },
    Icon: {
      externalLink: 'Lien externe',
    },
    RelatedArticles: {
      heading: 'Articles liés',
    },
  },
  ParentalRating: {
    rating1: 'Tous publics',
    rating2: 'Moins de 10 ans',
    rating3: 'Moins de 12 ans',
    rating4: 'Moins de 16 ans',
    rating5: 'Moins de 18 ans',
    rating6: 'Adultes',
  },
  Accessibility: {
    closedCaptioning: 'Sous-titrage malentendants',
    audioDescription: 'Audiodescription',
  },
  Date: {
    at: 'à',
    timeSeparator: 'h',
    broadcastToday: "aujourd'hui à %{hours}h%{minutes}",
    broadcastTodayLabel: '%{hours}h%{minutes}',
    broadcastTomorrow: 'demain à %{hours}h%{minutes}',
    broadcastTomorrowLabel: 'Demain %{hours}h%{minutes}',
    broadcastYesterday: 'hier à %{hours}h%{minutes}',
    broadcastFuture: 'le %{date} à %{hours}h%{minutes}',
    broadcastFutureLabel: '%{date} %{hours}h%{minutes}',
    TVODduration: "Vous avez jusqu'au %{date} pour consommer ce programme",
    availabilityText: "Disponible jusqu'au %{date}",
  },
  DaySelector: {
    error: 'Une erreur est survenue. Veuillez essayer ultérieurement',
  },
  DeleteAccount: {
    title: 'Supprimer mon compte',
  },
  Detail: {
    season: 'Saison',
  },
  DownloadToGo: {
    available:
      "Après achat ou location de ce contenu, vous pourrez profiter du téléchargement. Cette fonctionnalité vous permet de visionner votre contenu hors connexion, et d'assurer une bonne qualité de visionnage pour les faibles débits.",
    downloads: 'Téléchargements',
    noContent: 'Aucun contenu téléchargé.',
    notAvailable:
      "A la demande de l'éditeur, ce contenu n'est pas disponible au téléchargement",
    sizeConsumption: '%{used} utilisés sur %{total}',
  },
  DownloadToGoMacOsModal: {
    title: "Installez l'app myCANAL pour télécharger ce contenu",
    appNotAvailable:
      "Visionnez vos contenus hors connexion sur l'application myCANAL disponible sur MacOS, iOS, Android et Windows",
    downloadAppInfos:
      'Pour télécharger votre contenu, veuillez utiliser l’app myCANAL macOS. Application disponible sur le Mac App Store à partir de macOS Catalina 10.15.4',
    upgradeOs:
      "Vous devez mettre à jour votre version d'OS pour pouvoir utiliser l'app myCANAL macOS",
    closeBtn: 'Fermer',
    downloadAppBtn: 'Accéder à l’App Store',
    appStoreAlt: "Télécharger dans l'App Store (nouvel onglet)",
    microsoftStoreAlt: 'Télécharger pour Microsoft (nouvel onglet)',
    playStoreAlt: 'Disponible sur Google Play (nouvel onglet)',
  },
  EpisodesContainer: {
    switchEpisodesLabel: 'Résumés des épisodes',
    seeMoreButton: 'Voir plus',
  },
  ErrorMessages: {
    technicalIssue:
      'Nous avons rencontré un problème technique. Veuillez réessayer ultérieurement',
    orderMessage: 'Un problème est survenu avec votre commande',
    saleOrderMessage:
      "Un problème d'ordre technique est survenu. Votre commande n'a pas été enregistrée, veuillez la réitérer ou réessayer plus tard.",
    mandatoryField:
      "Votre commande n'a pas été enregistrée. Les champs ville, code postal et adresse sont obligatoires.",
    invalidCivility:
      "Votre commande n'a pas été enregistrée. Le champ civilité est invalide.",
    hibernateWrite:
      "Votre commande n'a pas été enregistrée. Le couple commune et code postal n’est pas présent dans notre référentiel adresse.",
    noPaymentMeansInfo:
      'Votre dossier présente une anomalie. Régularisez votre situation en contactant le Service Client.',
    noPaymentMeans:
      "Vous n'avez pas de moyen de paiement enregistré. Rendez-vous sur",
    unpaid: 'Votre dossier présente une anomalie. Rendez-vous sur',
    thirdParty: 'Votre dossier est tiers payeur. Rendez-vous sur',
    warnBizBlockingThirdPayers: `Votre abonnement est payé par un tiers. Veuillez vous rapprocher de ce dernier afin de réaliser votre achat.`,
    warnBizBlockingCaped: `Vous avez atteint le nombre maximum de commandes autoriséés sur le moyen de paiement de votre abonnement CANAL.`,
    warnBizBlockingUnpaid: `Nous rencontrons un problème sur votre moyen de paiement. Veuillez régulariser votre dossier aupres de votre service clients ou sur votre espace client avant de continuer la transaction.`,
    warnBizNoBill: `Le paiement par carte bancaire n'est pas disponible. Rendez-vous sur votre espace client  ou contactez votre service clients pour enregistrer un autre type de paiement.`,
    errSystemError: `Le service est indisponible pour le moment. Veuillez réessayer ulterieurement.`,
    errTokenIdentity: `Votre commande n'a pas pu aboutir. Mettez à jour vos droits avant de réessayer. Si le probleme persiste, veuillez contacter votre service Client`,
    errTokenIdentityPayment: `Votre commande n'a pas pu aboutir. Mettez à jour vos droits avant de réessayer. Si le probleme persiste, veuillez contacter votre service Client`,
    errOrderPaid: `Suite à un probleme technique, votre commande n’a pas abouti. Nous sommes désolés pour la gêne occasionnée.
    Vous pouvez faire une réclamation depuis votre espace client ou contacter votre Service clients.`,
    warnBizNoAlternatePaymentMode: `Le paiement n'est pas disponible pour le moment depuis votre application. Rendez-vous sur canalplus.com`,
    errNoSubscriber: `Votre commande n'a pas pu aboutir. Veuillez renouveler votre demande`,
    errBizBillConfirmation: `Votre commande n'a pas pu aboutir. Veuillez renouveler votre demande`,
    errBizBlockingVod: `Vous avez demandé le blocage des commandes de contenus CANAL VOD. Veuillez contacter votre service clients pour pouvoir acceder au service.`,
    errOrderPaidPayment: `Suite à un probleme technique, votre commande n’a pas abouti. Nous sommes désolés pour la gêne occasionnée.
    Vous pouvez faire une réclamation depuis votre espace client ou contacter votre Service clients.`,
    errSystemErrorPayment: `Le service est indisponible pour le moment. Veuillez réessayer ulterieurement.`,
    errBizBlockingCaped: `Vous avez atteint le nombre maximum de commandes autoriséés sur le moyen de paiement de votre abonnement CANAL.`,
    errBizBlockingNoBill: `Votre commande n'a pas pu aboutir. Veuillez renouveler votre demande`,
    errInconsistentOrder: `Votre commande n'a pas pu aboutir. Veuillez renouveler votre demande`,
    errOrderNotFound: `Votre commande n'a pas pu aboutir. Veuillez renouveler votre demande`,
    errJwtToken: `Suite à un probleme technique, le paiement n'a pas été pris en compte et votre commande n'a pas pu aboutir. Veuillez renouveler votre demande.`,
    warnBizBlockingUnpaidPayment: `Nous rencontrons un problème sur votre moyen de paiement. Veuillez régulariser votre dossier aupres de votre service clients ou sur votre espace client avant de continuer la transaction.`,
    errBillError: `Votre commande n'a pas pu aboutir. Veuillez renouveler votre demande`,
    errStopVod: `Vous avez demandé le blocage des commandes de contenus CANAL VOD. Veuillez contacter votre service clients pour pouvoir acceder au service.`,
    wrongCB:
      'Votre saisie semble incorrecte. Veuillez ressaisir votre moyen de paiement ou choisir une autre option de paiement',
    wrongCBData: 'Le numéro de carte semble incorrect. Veuillez réessayer',
    wrongCBValidation: 'Saisie incorrecte des informations de CB',
    paymentMessageSecondPart:
      'pour commander votre programme à l’aide d’une carte bancaire.',
    genericErrorTitle: 'Nous rencontrons un problème',
    genericErrorTxt: 'Nos équipes sont mobilisées pour le résoudre',
    invalidAddress: "Saisie de l'adresse incorrecte",
    noContent: "Aucun contenu n'est disponible pour l'instant.",
    noVod:
      'Les locations et achats ne sont pas autorisés pour ce compte client.',
    retry: 'Réessayer',
  },
  ErrorTemplate: {
    backToHome: "Retour à l'accueil",
    moreInfo: "Plus d'infos",
  },
  ExternalServiceModal: {
    title: 'Activation',
    streamInfos:
      'Vous devez activer votre compte %{channelName} pour lancer la lecture de ce contenu sur l’app %{channelName}.',
    downloadInfos:
      'Vous devez activer votre compte %{channelName} pour télécharger ce contenu sur l’app %{channelName}.',
    btn: 'Activer mon compte %{channelName}',
    partnerActivation: {
      title: 'Activation',
      streamInfos: `Vous devez activer votre compte %{channelName} pour lancer la lecture de ce contenu sur l'app %{channelName}. Un email de CANAL+ vous a été envoyé avec un lien d'activation. Vous pouvez aussi vous rendre sur la rubrique « Abonnement » de votre Espace Client web CANAL+ pour effectuer l'activation.`,
      downloadInfos: `Vous devez activer votre compte %{channelName} pour télécharger ce contenu sur l'app %{channelName}. Un email de CANAL+ vous a été envoyé avec un lien d'activation. Vous pouvez aussi vous rendre sur la rubrique « Abonnement » de votre Espace Client web CANAL+ pour effectuer l'activation.`,
      closeBtn: 'Fermer',
    },
  },
  FieldErrorMessage: {
    cgu: 'Vous devez accepter les Conditions Générales de vente pour continuer',
    email_login: "L'email est invalide",
    email_phone_login:
      'Veuillez vérifier le format de votre email ou de votre téléphone',
    msd_number: 'Vérifiez votre numéro de carte ou décodeur',
    num_abo:
      "Veuillez vérifier le pays d'abonnement et le numéro de réabonnement",
    new_password:
      'Votre nouveau mot de passe doit être différent de vos précédents mots de passe',
    new_password_too_recent:
      'Votre nouveau mot de passe a été utilisé trop récemment',
    old_password: 'Veuillez renseigner votre mot de passe actuel',
    password: 'Veuillez vérifier votre mot de passe',
    phone_email_login:
      'Veuillez vérifier le format de votre email ou de votre téléphone',
    phone_login: 'Le numéro de téléphone est invalide',
    resubscription_number:
      "Veuillez vérifier le pays d'abonnement et le numéro de réabonnement",
    subscription_number:
      "Veuillez vérifier le pays d'abonnement et le numéro de réabonnement",
    zip_code: 'Votre code postal doit être composé de 5 chiffres',
  },
  FunnelTvod: {
    addPromotionalCode: 'Ajouter un code promo',
    agree: 'J’accepte les',
    agreeTV: 'J’accepte les Conditions Générales de Vente',
    CBDisabledAlert:
      'Pour finaliser votre achat par carte bancaire rendez-vous sur le site web myCANAL. Vous pourrez ensuite retrouver tous vos achats et locations dans la rubrique “Mes vidéos”.',
    CBDisabledTitle: 'Paiement par carte bancaire indisponible sur TV',
    cgvAlert: 'Veuillez accepter les conditions générales de ventes',
    cgvLabel: 'Conditions Générales de Vente',
    cgvName: 'CGV',
    close: 'Fermer',
    deleteCBModal: {
      cancelBtn: 'Annuler',
      confirmBtn: 'Confirmer',
      label:
        'Êtes-vous sûr de vouloir supprimer l’enregistrement de la carte bancaire n° %{cardNumber} ?',
      subLabel: '(la suppression n’aura pas d’effet sur votre abonnement)',
    },
    deleteCBRegistered: 'Supprimer la carte bancaire enregistrée',
    deleteCBRegisteredBtn: 'Supprimer',
    insteadOf: 'au lieu de',
    libCB1Shot: 'Paiement par CB',
    promotionalCode: 'Code promo',
    reload: 'Actualiser',
    terms: 'Conditions Générales de Vente',
    validate: 'Valider',
    withdrawal:
      'J’accède immédiatement au service et je renonce au droit de rétractation',
    withdrawalAlert:
      'Pour accéder immédiatement au service, veuillez renoncer à votre droit de rétractation',
    withdrawalName: 'Droit de rétractation',
  },
  PrivacyManager: {
    acceptAll: 'Tout accepter',
    adsTitle: 'Publicité personnalisée',
    anonymous: 'Anonyme',
    authenticatedCase:
      'Ces réglages seront appliqués à l’ensemble des supports numériques connectés à ce compte CANAL+',
    confidentialityCGU: 'Confidentialité et conditions générales',
    refuseAll: 'Tout refuser',
    disabled: 'Désactivé',
    enabled: 'Activé',
    generalInfo:
      'CANAL+ accorde la plus grande importance à la gestion de vos donnés personnelles et vous permet de contrôler leur usage à travers les réglages ci-dessous. Vos données sont collectées afin de vous garantir le fonctionnement optimal de votre application et de personnaliser votre expérience ou vos publicités.\n',
    modal: {
      goBack: 'Retour',
      saveAndClose: 'Enregistrer et fermer',
      title: 'Vous souhaitez quitter cette page ?',
    },
    performanceTitle: 'Analyse de la performance',
    required: 'Requis',
    saveAndClose: 'Enregistrer et fermer',
    serviceTitle: 'Fonctionnement du service',
    title: 'À propos de vos données',
  },
  GDPRConsent: {
    title: 'À propos de vos données',
    generalInfo:
      "CANAL+ et ses filiales et nos partenaires, notamment les sociétés du groupe Vivendi, utilisons des traceurs pour mesurer l'utilisation et les performances de nos services et vous proposer des publicités plus pertinentes sur la base de votre navigation et de votre profil.",
    updateChoices:
      'Vous pouvez paramétrer vos choix par finalité et les mettre à jour à tout moment via notre rubrique Confidentialité.',
    authenticatedCase:
      'Votre choix sera valable sur l’ensemble des supports numériques de CANAL+ connectés à votre compte CANAL+',
    unloggedCase:
      'Votre choix ne sera valable que sur le support numérique actuellement utilisé. Si vous vous connectez à votre compte CANAL+, vos choix exprimés en mode connecté prévaudront.',
    learnMore: 'En savoir plus et paramétrer mes choix',
    serviceTitle: 'Fonctionnement du service',
    serviceText:
      "Ces identifiants permettent d'assurer le fonctionnement du service, en permettant l'authentification des utilisateurs, la sécurité et la supervision technique (anonyme) du service. Il n'est pas possible de désactiver ce réglage.",
    performanceTitle: 'Analyse de la performance',
    performanceText:
      'Ces identifiants permettent de mesurer l’utilisation et les performances de nos services pour mieux nous adapter à vos attentes. Lorsque le mode « Anonyme » est sélectionné, ces identifiants permettent de mesurer l’utilisation et les performances de nos services de manière totalement anonyme, sans suivi.',
    adsTitle: 'Publicité personnalisée',
    adsText:
      'Ces identifiants permettent, avec nos partenaires, de vous proposer des publicités adaptées à vos centres d’intérêt. Plus précisément, nous pourrons : adapter les contenus publicitaires selon la navigation de votre terminal ou les données personnelles que vous avez fournies, notamment en utilisant des fonctionnalités de ciblage (retargeting) pour vous présenter des publicités ou des contacts email suite à votre utilisation de nos services ; suivre la navigation ultérieure effectuée par votre terminal ; adapter la présentation du service auquel mène un contenu publicitaire ; limiter le nombre de fois où vous voyez une publicité ; calculer les sommes dues aux acteurs de la chaînes de diffusion publicitaire ; mesurer l’efficacité et l’impact d’une campagne publicitaire. En désactivant ces identifiants, vous ne serez pas exposés à moins de publicités, mais à des publicités ne correspondant pas nécessairement à vos centre d’intérets.',
    enabled: 'Activé',
    anonymous: 'Anonyme',
    disabled: 'Désactivé',
    required: 'Requis',
    refuseAll: 'Tout refuser',
    acceptAll: 'Tout accepter',
    saveAndClose: 'Enregistrer et fermer',
    confidentiality: 'Confidentialité',
    makeYourChoice: 'Faites votre choix :',
    noneTitle: 'Mesure anonyme',
    noneText: `CANAL+ a recours à des identifiants de mesure d’audience, nécessaires à la gestion de ses services, permettant de mesurer les performances de ses services et d’analyser les rubriques, pages et/ou contenus consultés, de manière totalement anonyme et agrégée et avec l’unique objectif de production, pour son compte exclusif, de statistiques ne contenant aucune donnée personnelle.
    Vous pouvez vous opposer au recours à ces identifiants de mesure anonyme et les désactiver ci-dessous.`,
  },
  HDPicto: {
    Icon: {
      hd: {
        description: 'Haute Définition',
      },
      uhd: {
        description: 'Ultra Haute Définition',
      },
    },
  },
  HorizontalPaging: {
    next: 'Suivant',
    previous: 'Précédent',
  },
  Icon: {
    check: 'Validé',
    close: 'Fermer',
    back: 'Retour',
    playlistOn: 'Ajouter à votre playlist',
    playlistOff: 'Retirer de votre playlist',
    download: 'Télécharger',
    live: 'Direct',
    loading: 'Chargement en cours',
    play: 'Lecture',
  },
  List: {
    selected: 'sélectionné',
  },
  LiveGrid: {
    ariaLabel: 'Les chaînes en direct',
    cards: {
      genericTitle: 'Informations non disponibles',
      genericSubtitle: 'Autres',
    },
    favorites: {
      emptyStateHeading: 'Aucune chaîne en favoris',
      emptyStateText:
        'Sélectionnez vos chaînes préférées afin de les retrouver facilement dans « Favoris »',
      editButton: 'Gérer les favoris',
      editButtonEmptyState: 'Ajouter',
    },
    editFavorites: {
      heading: 'Sélectionner vos chaines favorites',
      headingMobile: 'Chaînes favorites',
      saveButton: 'Valider',
      loadErrorMessage: 'Oups, une erreur est survenue',
      loadErrorButton: 'Recommencer',
      saveErrorMessage:
        'Nous avons rencontré une erreur pendant la sauvegarde. Veuillez recommencer plus tard.',
      closeErrorButton: 'Fermer',
    },
    multiLive: {
      cardMultiLiveCallToAction: 'Lancer le mode Multi live',
      buttonLaunchMultiLive: 'Activer la mosaique',
      fetchErrorMessage: 'Oups, une erreur est survenue',
      fetchErrorButton: 'Recommencer',
    },
  },
  LiveTV: {
    allChannels: 'Toutes les chaînes',
  },
  LogoAvailabilities: {
    text: 'Disponible',
  },
  MediaCardBody: {
    broadcastInfoLabel: 'Diffusé %{date} sur %{channelName}',
    onGoingBroadcastInfoLabel: 'En cours de diffusion sur %{channelName}',
  },
  MediaCardHeader: {
    error: 'Une erreur est survenue. Veuillez essayer ultérieurement',
    prospect: 'Envie de regarder',
    broadcastingDate: 'Diffusé',
    noMoreDiffusion: "Ce contenu n'est plus diffusé",
    unavailableContent: 'Ce contenu est indisponible',
    inAppMessageApple:
      'Pour regarder ce programme, veuillez modifier votre offre depuis l’App Store d’Apple',
    inAppMessageGoogle:
      'Pour regarder ce programme, veuillez modifier votre offre depuis le Google Play Store',
    scrollerEpisodeText: 'Voir les épisodes',
  },
  MetaTags: {
    title: 'en streaming direct et replay sur CANAL+',
    tvodTitle: 'en VOD et en téléchargement sur CANAL+',
  },
  Mood: {
    disclaimer: 'Donner votre avis',
    dislike: "Je n'aime pas",
    neutral: 'Indifférent',
    like: "J'aime",
    noopinion: 'Opinion',
  },
  MoodButton: {
    notAvailableRecoOff:
      'Pour accéder à cette fonctionnalité, vous devez activer la recommandation et la personnalisation dans votre menu Réglages',
  },
  Sharing: {
    title: 'Partager',
  },
  MyAccountTemplate: {
    accessAccount: 'Accéder à mon Espace Client',
    cancel: 'Résilier',
    modifyEmail: 'Modifier mon email',
    modifyLabel: 'Modifier',
    modifyPwd: 'Modifier mon mot de passe',
    myAccount: 'Mon compte',
    myCanalAccount: 'Mon compte CANAL +',
    name: 'Nom',
    offer: 'Votre offre sans engagement',
    personalInformation: 'Mes infos personnelles',
    reactivateRightsInfo:
      'Si votre liste de chaînes ne vous semble pas à jour, vous pouvez réactiver vos droits',
    subscription: 'Mon abonnement',
    subscriptionLabel: 'Abonnement',
    updateRightsAction: 'Réactiver',
    updateRightsLabel: 'Mise à jour des droits',
  },
  Navigation: {
    dropdownLabel: 'Plus',
  },
  OfflineTemplate: {
    title: 'Problème de connexion',
    offlineDescription:
      'Vous êtes actuellement hors ligne, connectez-vous pour afficher cette page.',
    onlineDescription:
      'Vous semblez être de nouveau en ligne, cliquez sur le bouton pour recharger.',
    retry: 'Réessayer',
    reload: 'Recharger',
  },
  PartnerSelection: {
    backIconTitle: 'Icône de retour',
  },
  PasswordInput: {
    numberCharacter: '8 caractères minimum',
    lowerCaseUpperCaseCharacter:
      'Au moins une majuscule, une minuscule et un chiffre',
    noMandatoryCharacter: "Ne contient pas les caractères suivants : ‹ › ' “ ",
  },
  PlaylistButton: {
    added: 'Ajouté à votre playlist',
    alreadyAdded: 'Déjà ajouté à la playlist',
    buttonLabel: 'Playlist',
    removed: 'Retiré de votre playlist',
    error: 'Echec, veuillez réessayer',
    notAvailableRecoOff:
      'Pour accéder à cette fonctionnalité, vous devez activer la recommandation et la personnalisation dans votre menu Réglages',
  },
  ProfileManager: {
    addProfile: 'Ajouter un profil',
    add: 'Ajouter',
    avatarPrefix: 'Avatar %{displayName}',
    chooseAvatar: 'Choisir un avatar',
    closeModal: 'Fermer',
    deleteProfile: 'Supprimer le profil',
    deleteProfileCancelButton: 'Annuler',
    deleteProfileConfirmButton: 'Ok',
    deleteProfileConfirmText:
      'Souhaitez-vous vraiment supprimer le profil %{displayName} ?',
    deleteProfileConfirmTitle: 'Confirmer la suppression',
    deleteToCreate: 'Vous devez supprimer un profil pour en créer un nouveau.',
    edit: 'Modifier un profil',
    editAriaLabel: 'Modifier le profil %{displayName}',
    editAvatarAriaLabel: "Modifier l'avatar",
    inputPlaceholder: 'Nom',
    kidsProfileSubText:
      "Ce profil est un profil enfant permettant d'accéder directement à un espace personnalisé, sécurisé et approprié à l'âge de votre enfant.",
    kidsProfileTitle: 'Profil Kids',
    kidsProfileSettings: 'Profil Kids',
    manage: 'Gérer les profils',
    maxAmount: 'Vous avez atteint le nombre maximum de profils sur ce compte.',
    newWindowAriaLabel: '(nouvelle fenêtre)',
    profileIconAriaLabel: 'Menu, compte et connexion',
    submitCreation: 'Valider',
    submitEdition: 'Valider',
    title: 'Changer de profil',
    userReachedMaxNumberProfilesTitle: 'Nombre maximum de profils',
    userReachedMaxNumberProfilesText:
      'Vous avez atteint le nombre maximum de 10 profils sur ce compte. Vous devrez supprimer un profil pour en créer un nouveau.',
  },
  ProspectLoginMessage: {
    message: 'Plus de 200 chaines et la TNT en direct live et replay.',
    commitment: 'Sans engagement',
    offer: 'Découvrir nos offres',
  },
  SafetyDisclaimer: {
    error:
      'Une erreur serveur est survenue. Veuillez réessayer ultérieurement.',
    wrongCode: 'Code incorrect. Veuillez réessayer',
    close: 'Terminer',
    [SafetyCodeContext.Parental]: {
      title: 'Programmes adultes',
      tooltip:
        "Si vous avez oublié votre code parental, merci de contacter le Service Client au <span> %{phoneNumber} </span> (0,34€/min TTC depuis un poste fixe). Horaire d'ouverture: 8h à 22h du lundi au samedi (sauf jours fériés)",
      forgotten: 'Code parent oublié ?',
      safetyCodeTitle: 'Code parental',
      safetyCodeInstruction: 'Saisissez votre code parent à 4 chiffres',
      resetSafetyCode: 'Réinitialiser votre code parental',
      forgottenTitle: 'Mot de passe oublié',
      forgottenText:
        'Munissez-vous de votre mobile pour modifier votre code parent ou rendez-vous sur votre espace client : ',
      lockedTitle: 'Saisie du code parental bloquée',
      lockedText:
        'Suite à un trop grand nombre de tentatives incorrectes, l’accès aux contenus soumis au code parental est bloqué pendant 2h. Vous pouvez réessayer ultérieurement ou réinitialiser votre code parental.',
      createTitle: 'Gérer votre code parental',
      createText:
        "Le code parental protège l'accès aux programmes de catégorie V (programmes adultes). Pour gérer votre code parental, rendez-vous sur ",
      subCreateText: 'ou scannez le QR code.',
      inOffer:
        "La création d'un code parental est nécessaire pour accéder à ce programme.",
      inOfferOrange: `La création d'un code adulte Orange est nécessaire pour accéder à ce programme.`,
      adultCodeOrange: 'code adulte Orange',
      createCodeOrange: 'Créer votre code adulte Orange',
      codeOrangeforget: 'Code adulte Orange oublié ?',
      enterCodeOrange: 'Saisir votre code adulte Orange',
      safetyCodeInstructionOrange:
        'Saisissez votre code adulte Orange à 4 chiffres',
      notInOffer:
        "L'accès aux programmes adultes n'est pas disponible, vous devez l'activer dans votre abonnement ou modifier votre offre.",
      createCode: 'Créer votre code parental',
      enterCode: 'Saisir votre code parental',
      conditions: 'En poursuivant, vous acceptez les conditions ci-dessus.',
      warningDescription:
        'Ce service est verrouillé en raison de sa nocivité pour les mineurs car il propose des programmes de catégorie V interdits aux moins de 18 ans. Le fait de permettre à un mineur de voir ou d’entendre un programme de catégorie V est passible d’une peine de 5 ans d’emprisonnement et de 75 000 euros d’amende.',
    },
    [SafetyCodeContext.Purchase]: {
      title: 'Code Achat',
      tooltip:
        "Si vous avez oublié votre code d'achat, merci de contacter le Service Client au <span> %{phoneNumber} </span> (0,34€/min TTC depuis un poste fixe). Horaire d'ouverture: 8h à 22h du lundi au samedi (sauf jours fériés)",
      forgotten: "Code d'achat oublié ?",
      safetyCodeTitle: "Code d'achat",
      safetyCodeInstruction: "Saisissez votre code d'achat à 4 chiffres",
      resetSafetyCode: "Réinitialiser votre code d'achat",
      forgottenTitle: 'Mot de passe oublié',
      forgottenText:
        "Munissez-vous de votre mobile pour modifier votre code d'achat ou rendez-vous sur votre espace client : ",
      lockedTitle: "Saisie du code d'achat bloquée",
      lockedText:
        "Suite à un trop grand nombre de tentatives incorrectes, l’accès aux contenus soumis au code d'achat est bloqué pendant 2h. Vous pouvez réessayer ultérieurement ou réinitialiser votre code d'achat.",
      createTitle: "Gérer votre code d'achat",
      createText:
        'Le code achat permet de sécuriser les futurs achats réalisés sur votre compte. Pour créer votre code achat scanner le QR code ou rendez-vous sur: ',
      subCreateText: '',
      needPurchaseCode:
        'Pour éviter un achat accidentel créez votre code achat. Vous pouvez le gérer à tout moment depuis la rubrique Mon Compte',
      createCode: "Créer votre code d'achat",
      enterCode: "Saisir votre code d'achat",
      conditions: 'En poursuivant, vous acceptez les conditions ci-dessus.',
      warningDescription: '',
    },
  },
  Search: {
    searchInputLabel: 'Rechercher',
    activateSearchTriggerLabel: 'Rechercher',
    closeSearchTriggerLabel: 'Fermer la recherche',
    noResultTitle: 'Aucun résultat',
  },
  ServerError: {
    title: 'Nous rencontrons un problème !',
    text: 'Nous faisons notre maximum pour réparer le service et éliminer tous les aliens.',
  },
  SettingsTemplate: {
    disabled: 'Désactivé',
    enabled: 'Activé',
    errorServer:
      'Une erreur serveur est survenue. Veuillez réessayer ultérieurement.',
    preferences: {
      autoplay: {
        title: 'Lecture automatique',
        trailer: {
          explanation: 'Lecture automatique de la bande-annonce',
          soundExplanation: "Autoriser l'audio lors de la lecture automatique",
          title: 'Bande-annonce',
        },
        videoPreviews: {
          explanation: 'Lecture automatique pendant la navigation',
          soundExplanation: "Autoriser l'audio lors de la lecture automatique",
          title: 'Aperçus vidéos',
        },
      },
      imageQuality: {
        explanation:
          "Le saviez-vous ? Le choix d'une qualité adaptée contribue à réduire l’empreinte carbone.",
        high: 'Maximale',
        low: 'Réduite',
        medium: 'Intermédiaire',
        title: 'Qualité des images',
      },
      personalization: {
        explanation:
          'Ces fonctionnalités nécessitent la collecte de données d’usage. En vous opposant à la collecte de ces données, toutes ces fonctionnalités seront désactivées sur tous vos profils créés et les données précédemment collectées seront effacées. Vous pouvez également supprimer un profil sans désactiver la fonctionnalité de recommandation et personnalisation pour les autres profils. Pour cela, il vous suffit de vous rendre dans « Gérer les profils » et de sélectionner le profil à supprimer.',
        list: {
          item1:
            'reprise de lecture d’un programme au moment où vous l’avez arrêté, y compris sur un autre appareil',
          item2: 'recommandations personnalisées de programmes',
          item3: 'statut de lecture sur chaque épisode de série',
          item4: 'gestion de votre playlist',
          item5: 'gestion de votre historique de visualisations',
          item6: 'déclarations « j’aime » / « j’aime pas »',
        },
        subtitle:
          'CANAL+ vous propose une expérience personnalisée, sur chaque profil que vous aurez créé, en fonction de vos usages :',
        modal: {
          cancel: 'Annuler',
          confirm: 'Confirmer',
          explanation:
            'Votre profil de recommandation, votre historique de visualisation, vos déclarations d’opinions et mises en playlist seront perdus et les fonctionnalités de personnalisation seront désactivées sur l’ensemble de vos appareils dans un délai de 24 heures.',
          title: 'Désactiver la recommandation et personnalisation ?',
        },
        title: 'Personnalisation',
      },
      playNextEpisode: {
        explanation: "Les épisodes de séries s'enchaînent automatiquement",
        title: 'Enchaînement des épisodes',
      },
      title: 'Préférences',
      whoIsWatching: {
        explanation:
          "Ce réglage permet d'afficher au démarrage de l'application la liste des profils.",
        title: 'Profils au démarrage',
      },
    },
    title: 'Réglages',
    videoQuality: {
      title: 'Qualité vidéo',
      realTimeStreaming: {
        title: 'Streaming Temps Réel',
        explanation: `Le streaming temps réel diminue fortement le délai entre la captation TV et l'affichage sur votre équipement pour se rapprocher au plus près du direct. La fonctionnalité est accessible uniquement depuis certaines chaînes LIVE (voir la liste des chaînes sur CANAL+ Assistance). Attention: ce réglage est susceptible de générer des périodes de rechargement de la vidéo selon votre connexion. Ce réglage sera progressivement amélioré et déployé sur d'autres chaînes.`,
      },
      streaming: {
        explanation:
          'Ce réglage permet de réduire la consommation de votre forfait mobile en bridant la qualité vidéo lorsque vous n’êtes pas connecté en Wifi.',
        information:
          "Le saviez-vous ? Le choix d'une qualité adaptée contribue à réduire l’empreinte carbone.",
        maximum: {
          explanation:
            'Jusqu’à 1080p - 4K, selon la définition de votre équipement. Consomme environ %1$@.',
          title: 'Maximale',
        },
        medium: {
          explanation:
            'Jusqu’à 720p : recommandé pour un PC ou Mac. Consomme environ %1$@.',
          title: 'Intermédiaire',
        },
        minimum: {
          explanation:
            'Jusqu’à 480p : recommandé pour un smartphone en mobilité. Consomme environ %1$@.',
          title: 'Minimale',
        },
        title: 'Streaming',
      },
    },
    videoSettings: {
      hdr: {
        auto: 'Auto',
        explanation:
          "En activant cette option, le HDR sera disponible sur certaines chaînes (liste des chaînes concernées à retrouver sur le site de l'assistance CANAL+). En utilisant cette fonction sur certains appareils non compatibles, il est possible que vous obteniez un écran noir au lieu de la vidéo sur les chaînes compatibles avec le HDR. Des distorsions de couleurs peuvent également exister.",
        title: 'HDR sur certaines chaînes',
      },
      title: 'Réglages vidéos',
    },
  },
  ShowcaseTemplate: {
    OfferHighlight: {
      pauseBtn: 'Lire la bande-annonce des programmes (son inactif)',
      playBtn: 'Mettre en pause la bande-annonce des programmes (son inactif)',
    },
  },
  Slideshow: { skip: 'Passer' },
  Sport: {
    now: 'Maintenant',
    lessThanOneHourAgo:
      'Il y a %{smart_count} minute |||| Il y a %{smart_count} minutes',
    betweenOneAndTwoHoursAgo: 'Il y a 1 h %{minutes} mn',
    someHoursAgo:
      'Il y a %{smart_count} heure |||| Il y a %{smart_count} heures',
    someDaysAgo: 'Il y a %{smart_count} jour |||| Il y a %{smart_count} jours',
  },
  StartOver: {
    seeLive: 'Accéder au live',
    seeFromBeginning: 'Voir du début',
  },
  StoreRedirection: {
    title:
      'Profitez de la meilleure expérience vidéo en téléchargeant l’application mobile et tablette',
    buttonText: "Télécharger l'application",
  },
  Streaming: {
    realtime: 'Streaming temps réel',
    explanation:
      "Le streaming temps réel diminue fortement le délais entre la captation TV et l'affichage sur votre équipement pour se rapprocher au plus près du direct. La fonctionnalité est accessible uniquement depuis certaines chaînes LIVE (voir la liste des chaînes sur CANAL+ Assistance).<br/>Attention: ce réglage est susceptible de générer des périodes de rechargement de la vidéo selon votre connexion. Ce réglage sera progressivement amélioré et déployé sur d'autres chaînes.",
    whoWatching: 'Qui regarde ?',
    dontShow: 'Ne plus afficher cet écran ?',
    showOnLaunch: 'Afficher cet écran à chaque démarrage ?',
    editInSettings:
      'Vous pourrez modifier cette préférence dans la rubrique « Réglages » de l’application',
    editInMore:
      'Vous pourrez modifier cette préférence dans la rubrique « En plus » de l’application',
    options: 'Oui / Non',
  },
  Video: {
    fullscreen: 'Voir en plein écran',
    watch: 'Voir la video',
    play: 'Lecture de la video',
    pause: 'Pause de la video',
    mute: 'Désactiver le son de la video',
    unmute: 'Activer le son de la video',
  },
  TvGuide: {
    search: {
      placeholder: 'Accès rapide : Saisissez un nom de chaîne',
      zapNumber: 'Canal',
    },
  },
  ParentalCode: {
    title: 'Code parental',
  },
  PurchaseCode: {
    title: "Code d'achat",
  },
  PurchaseCodeDeletion: {
    title: 'Désactivation',
  },
  ViolentContent: {
    title: 'Gérer la protection des contenus violents',
  },
  VoucherFeedback: {
    success: 'Code promo appliqué',
    invalid: "Ce code promo n'est pas valide",
  },
  VoucherInput: {
    promotionalCode: 'Code promo',
  },
  WhoIsWatching: {
    title: 'Qui regarde ?',
    toggleNotDisplay: 'Ne plus afficher cet écran ?',
    toggleNotDisplayDesc:
      'Vous pourrez modifier cette préférence dans la rubrique « Réglages » de l’application.',
  },
  Zone: {
    title: 'Sélectionnez votre zone géographique',
    subtitle_france: 'France et Outre-mer',
    subtitle_europe: 'Europe',
    subtitle_africa: 'Afrique',
    subtitle_others: 'Autres',
  },
};

export default LOCALE_FR_FR;
