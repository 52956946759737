import { DIMENSIONS, Ratio, type ResizeMode } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import type { JSX } from 'react';
import { ImageType } from '../../ImageType/ImageType';
import { Poster } from '../../Poster/Poster';
import { PosterPlaceholder } from '../../Poster/PosterPlaceholder';
import styles from './ImageWallContent.module.css';

export type ImageWallContentProps = {
  imageAlt?: string;
  imageUrl?: string;
  isTvDevice?: boolean;
  resizeMode?: ResizeMode;
  logoAlt?: string;
  logoUrl?: string;
  ratio: Ratio;
  title?: string;
  dataTestId?: string;
};

export function ImageWallContent({
  imageAlt,
  imageUrl,
  isTvDevice = false,
  resizeMode,
  logoAlt,
  logoUrl,
  ratio,
  title,
  dataTestId,
}: ImageWallContentProps): JSX.Element {
  const availableRatioShowcaseRatio = [Ratio.Ratio34, Ratio.Ratio169] as const;

  return (
    <div
      className={classNames(styles.ImageWallContent, {
        [styles['ImageWallContent--shadow']]: !!title,
      })}
      data-testid={dataTestId}
    >
      {imageUrl &&
      availableRatioShowcaseRatio.includes(
        ratio as (typeof availableRatioShowcaseRatio)[number]
      ) ? (
        <Poster
          altImage={imageAlt}
          image={{ default: imageUrl }}
          dimensions={
            DIMENSIONS.SHOWCASE_IMAGE_WALL[
              ratio as (typeof availableRatioShowcaseRatio)[number]
            ]
          }
          isTvDevice={isTvDevice}
          resizeMode={resizeMode}
        />
      ) : (
        <PosterPlaceholder />
      )}
      {(title || logoUrl) && (
        <div className={styles.ImageWallContent__contentWrap}>
          {logoUrl && (
            <ImageType
              altImage={logoAlt || title}
              className={styles.ImageWallContent__logo}
              dimensions={DIMENSIONS.LOGO_CHANNEL.large}
              URLImage={{ default: logoUrl }}
              isTvDevice={isTvDevice}
              resizeMode={resizeMode}
            />
          )}
          {title && (
            <span className={styles.ImageWallContent__title}>{title}</span>
          )}
        </div>
      )}
    </div>
  );
}
