import type { ButtonWrapperProps } from '@canalplus/dive';
import { Button, Media } from '@canalplus/dive';
import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { PlayPauseControlButton } from '@canalplus/oneplayer-shared-components';
import type {
  ApiV2Context,
  ApiV2StrateContentButton,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2StrateBackgroundParameters } from '@dce-front/hodor-types/api/v2/page/dtos/strates/offer/offer_highlight';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import Linker from '../../../../../components/Linker/Linker';
import ThumborMediaImage from '../../../../../components/ThumborMediaImage/ThumborMediaImage';
import { Actions } from '../../../../../components/Video';
import { useIsVideoReadyToPlay } from '../../../../../components/Video/context/hooks/useIsVideoReadyToPlay';
import styles from './InfoLayer.css';

const cx = classNames.bind(styles);

type InfoLayerProps = {
  trackingContext?: ApiV2Context;
  isTvDevice: boolean;
  title?: string;
  description?: string;
  buttons?: ApiV2StrateContentButton[];
  backgroundParameters?: ApiV2StrateBackgroundParameters;
  URLLogoChannel?: string;
};

export default function InfoLayer({
  trackingContext,
  isTvDevice,
  title,
  description,
  buttons,
  backgroundParameters,
  URLLogoChannel,
}: InfoLayerProps): JSX.Element {
  const isVideoReadyToPlay = useIsVideoReadyToPlay();
  const { imageCredit, videoCredit } = backgroundParameters || {};

  return (
    <div
      className={cx('offerHighlight__infoLayer', {
        'offerHighlight__infoLayer--noPictureOrVideo': !backgroundParameters,
      })}
    >
      <div className={cx('offerHighlight__infoWrap')}>
        {isTvDevice && URLLogoChannel && (
          <Media
            width="12.375rem"
            className={cx('offerHighlight__logo')}
            variant="ghost"
            image={
              <ThumborMediaImage
                alt=""
                url={URLLogoChannel}
                dimensions={{
                  default: DIMENSIONS.OFFER_HIGHLIGHT.logo,
                }}
              />
            }
          />
        )}
        {title && <span className={cx('offerHighlight__title')}>{title}</span>}
        {description && (
          <p className={cx('offerHighlight__description')}>{description}</p>
        )}
        <div className={cx('offerHighlight__actionsWrap')}>
          {buttons &&
            buttons.length !== 0 &&
            buttons.map((button) => {
              return (
                <Button
                  key={button.label}
                  className={cx('offerHighlight__button')}
                  renderWrapper={({
                    className,
                    children,
                  }: ButtonWrapperProps) => (
                    <Linker
                      data={{ mainOnClick: button.onClick, trackingContext }}
                      objKey="onClick"
                      className={className}
                      ariaLabel={button.ariaLabel}
                    >
                      {children}
                    </Linker>
                  )}
                >
                  {button.label}
                </Button>
              );
            })}
          {imageCredit && (
            <span
              className={cx('offerHighlight__credit', {
                'offerHighlight__credit--hidden': isVideoReadyToPlay,
              })}
            >
              {imageCredit}
            </span>
          )}
          {videoCredit && (
            <span
              className={cx(
                'offerHighlight__credit',
                'offerHighlight__credit--hidden',
                {
                  'offerHighlight__credit--show': isVideoReadyToPlay,
                }
              )}
            >
              {videoCredit}
            </span>
          )}
          {!isTvDevice && (
            <div className={cx('offerHighlight__videoButton')}>
              <Actions>
                <PlayPauseControlButton />
              </Actions>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
