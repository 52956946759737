import { Media } from '@canalplus/dive';
import { DIMENSIONS } from '@canalplus/mycanal-commons';
import type { IBackgroundParameters } from '@canalplus/mycanal-sdk';
import type { JSX } from 'react';
import ThumborMediaImage from '../../../../../components/ThumborMediaImage/ThumborMediaImage';
import { Video } from '../../../../../components/Video';
import { useIntersectionObserverForVideo } from '../../../../../components/Video/hooks/useIntersectionObserverForVideo';
import styles from './MediaLayer.css';

type MediaLayerProps = {
  isSmallScreenWidth: boolean;
  backgroundParameters: IBackgroundParameters;
  isVideoEnabled: boolean;
};

export default function MediaLayer({
  isSmallScreenWidth,
  backgroundParameters,
  isVideoEnabled,
}: MediaLayerProps): JSX.Element {
  const { intersectionRef, isRootRefIntersecting } =
    useIntersectionObserverForVideo();

  const coverImage = (
    <Media
      image={
        <ThumborMediaImage
          alt=""
          url={backgroundParameters?.URLImageOptimizedRegular}
          urlMobile={backgroundParameters?.URLImageOptimizedCompact}
          dimensions={{ default: DIMENSIONS.OFFER_HIGHLIGHT.image }}
        />
      }
    />
  );

  const { URLVideoOptimizedCompact = '', URLVideoOptimizedRegular = '' } =
    backgroundParameters || {};
  const videoUrl =
    (isSmallScreenWidth && URLVideoOptimizedCompact) ||
    !URLVideoOptimizedRegular
      ? URLVideoOptimizedCompact
      : URLVideoOptimizedRegular;

  return (
    <div className={styles.mediaLayer} ref={intersectionRef}>
      {videoUrl && isVideoEnabled ? (
        <Video
          cover={coverImage}
          isVisible={isVideoEnabled && isRootRefIntersecting}
          isLoop
          isMuted
          videoContent={{
            url: videoUrl,
            encryption: 'clear',
            format: 'mp4',
          }}
        />
      ) : (
        coverImage
      )}
    </div>
  );
}
