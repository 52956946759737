import type {
  ApiV2Context,
  ApiV2StrateContentButton,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2StrateBackgroundParameters } from '@dce-front/hodor-types/api/v2/page/dtos/strates/offer/offer_highlight';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { VideoProvider } from '../../../../components/Video';
import { useOnFocusable } from '../../../../helpers/hooks/useOnFocusable';
import type { FocusManager } from '../../../../helpers/oneNavigation/FocusManager';
import InfoLayer from './InfoLayer/InfoLayer';
import MediaLayer from './MediaLayer/MediaLayer';
import styles from './OfferHighlight.css';

const cx = classNames.bind(styles);

export type OfferHighlightContent = {
  backgroundParameters: ApiV2StrateBackgroundParameters;
  buttons?: ApiV2StrateContentButton[];
  description?: string;
  label?: string;
  textSide?: string;
  title?: string;
  type?: string;
  URLLogoChannel?: string;
};

export type OfferHighlightProps = {
  contents: OfferHighlightContent[];
  isSmallScreenWidth: boolean;
  isVideoEnabled: boolean;
  trackingContext?: ApiV2Context;
  isTvDevice: boolean;
  focusManager?: FocusManager;
};

export function OfferHighlight({
  contents,
  isTvDevice,
  isSmallScreenWidth,
  isVideoEnabled,
  trackingContext,
  focusManager,
}: OfferHighlightProps): JSX.Element {
  const { backgroundParameters, title, description, buttons, URLLogoChannel } =
    contents[0] || {};

  const hasPictureOrVideo = !backgroundParameters;

  useOnFocusable(focusManager);

  return (
    <VideoProvider>
      <div
        className={cx('offerHighlight', 'offerHighlightFocus', {
          'offerHighlight__noPictureOrVideo': hasPictureOrVideo,
        })}
      >
        {!hasPictureOrVideo && (
          <MediaLayer
            isSmallScreenWidth={isSmallScreenWidth}
            backgroundParameters={backgroundParameters}
            isVideoEnabled={isVideoEnabled}
          />
        )}
        <InfoLayer
          trackingContext={trackingContext}
          isTvDevice={isTvDevice}
          title={title}
          description={description}
          buttons={buttons}
          backgroundParameters={backgroundParameters}
          URLLogoChannel={URLLogoChannel}
        />
      </div>
    </VideoProvider>
  );
}
