import { DIMENSIONS, type ResizeMode } from '@canalplus/mycanal-commons';
import type { ImgHTMLAttributes, JSX } from 'react';
import { useMemo } from 'react';
import { ImageType } from '../ImageType/ImageType';
import styles from './ChannelsGrid.module.css';

// TODO: Use proper Hodor types once available
export type ChannelsGridContent = {
  description?: string;
  epgID?: number;
  title?: string;
  URLImage: string;
  URLImageForDarkMode?: string;
  URLImageForLightMode?: string;
};

export type ChannelsGridProps = {
  contents?: ChannelsGridContent[];
  description?: string;
  isTvDevice?: boolean;
  resizeMode?: ResizeMode;
  title?: string;
  loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
};

export function ChannelsGrid({
  contents = [],
  description,
  isTvDevice = false,
  resizeMode,
  title,
  loading,
}: ChannelsGridProps): JSX.Element {
  const URLImageMap = useMemo(
    () => contents.map((content) => ({ default: content.URLImage })),
    [contents]
  );

  return (
    <div className={styles.ChannelsGrid}>
      {title && <span className={styles.ChannelsGrid__title}>{title}</span>}

      {description && (
        <p className={styles.ChannelsGrid__description}>{description}</p>
      )}

      {!!contents.length && (
        <ul className={styles.ChannelsGrid__channelsWrap}>
          {contents.map(({ epgID, title: contentTitle }, index) => (
            <li
              data-testid="channelsgrid-li"
              className={styles.ChannelsGrid__channel}
              key={epgID || index}
            >
              <ImageType
                altImage={contentTitle}
                className={styles.ChannelsGrid__channel__image}
                dimensions={DIMENSIONS.CHANNELS_GRID_IMAGE}
                URLImage={URLImageMap[index]}
                isTvDevice={isTvDevice}
                resizeMode={resizeMode}
                loading={loading}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
