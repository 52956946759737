import { Button, Link, LinkArrowRightSvg } from '@canalplus/dive';
import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import { memo } from 'react';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import Linker from '../../Linker/Linker';
import type { LinkerSettings } from '../../Linker/types';
import styles from './ButtonInline.css';

export type ButtonResources = {
  text: string;
  type: string;
};

export type ButtonInlineContent = {
  label: string;
  type: string;
  style: string;
  onClick: ApiV2OnClick;
};

export type ButtonInlineProps = {
  content: ButtonInlineContent;
  id: string;
};

function ButtonInline({ content, id }: ButtonInlineProps): JSX.Element {
  const { label } = content || {};
  const isTvDevice = useIsTvDevice();

  return (
    <div className={classNames(styles.buttonInline)}>
      <Linker
        id={id}
        data={{ mainOnClick: content.onClick }}
        renderWrapper={(linkerSettings: LinkerSettings) => {
          const { onClick, href, target } = linkerSettings;
          return isTvDevice ? (
            <Button
              variant="text"
              font="hind"
              icon={<LinkArrowRightSvg />}
              onClick={onClick}
              id={id}
            >
              {label}
            </Button>
          ) : (
            <Link
              onClick={onClick}
              href={href}
              hasIcon
              iconPosition="left"
              target={target}
              id={id}
            >
              {label}
            </Link>
          );
        }}
      />
    </div>
  );
}

export default memo(ButtonInline);
