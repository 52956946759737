import type {
  ApiV2Context,
  ApiV2StrateContentButton,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { JSX } from 'react';
import { Linker as FallbackLinker } from '../../Linker/Linker';
import styles from './ImageWallCta.module.css';

export type ImageWallCtaProps = {
  button?: ApiV2StrateContentButton;
  description?: string;
  isTvDevice?: boolean;
  Linker?:
    | React.ForwardRefExoticComponent<any>
    | ((props: any) => React.ReactElement);
  subtitle?: string;
  title?: string;
  trackingContext?: ApiV2Context;
};

export function ImageWallCta({
  button,
  description,
  isTvDevice = false,
  Linker = FallbackLinker,
  subtitle,
  title,
  trackingContext,
}: ImageWallCtaProps): JSX.Element {
  const { onClick } = button || {};
  if (isTvDevice) {
    return (
      <Linker
        className={styles.ImageWallCta__linkerTV}
        data={{ mainOnClick: { ...onClick, trackingContext } }}
        objKey="onClick"
      >
        <div className={styles.ImageWallCta}>
          {title && <h2 className={styles.ImageWallCta__title}>{title}</h2>}
          {subtitle && (
            <div className={styles.ImageWallCta__subtitle}>{subtitle}</div>
          )}
          {description && (
            <p className={styles.ImageWallCta__description}>{description}</p>
          )}
        </div>
      </Linker>
    );
  }

  return (
    <div data-testid="image-wall-cta" className={styles.ImageWallCta}>
      {title && <h2 className={styles.ImageWallCta__title}>{title}</h2>}
      {subtitle && (
        <div className={styles.ImageWallCta__subtitle}>{subtitle}</div>
      )}
      {description && (
        <p className={styles.ImageWallCta__description}>{description}</p>
      )}
      {button && (
        <Linker
          className={styles.ImageWallCta__button}
          data={{ mainOnClick: { ...onClick, trackingContext } }}
          objKey="onClick"
        >
          <span className={styles.ImageWallCta__buttonText}>
            {button.label || button.title}
          </span>
        </Linker>
      )}
    </div>
  );
}
